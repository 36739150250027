@import url('../../variables.less');
.chart-header {
  display: flex;
  padding: 16px 10px;
  .market-symbol {
    display: flex;
    align-items: center;
    padding: 10px;
    &:hover {
      cursor: pointer;
    }
    span {
      font-size: 16px;
      font-weight: bold;
      margin-right: 5px;
    }
    margin-right: 30px;
  }
  .market-price {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    margin-right: 30px;
    display: flex;
    align-items: center;
  }
  .info {
    margin-right: 20px;
    .title {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }
    & > div:last-child {
      font-size: 12px;
      line-height: 1.6;
    }
  }

  @media screen and (max-width: 768px) {
    .day-change,
    .day-high,
    .day-low,
    .day-volume {
      display: none;
    }
  }
}

.chart-header-mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .chart-header {
    display: none;
  }
  div.chart-header-mobile {
    border-bottom: 1px solid #303030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    .title {
      font-size: 16px;
      margin-right: 5px;
    }
    .title {
      font-weight: bold;
    }
    .current-price,
    .percentage,
    .high {
      font-size: 14px;
    }
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & > span:first-child {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
      }
      & > span:first-child.current-price {
        color: rgba(255, 255, 255, 0.85);
        font-size: 14px;
      }
    }
  }
}

.percentage {
  &.up {
    color: @green;
  }
  &.down {
    color: @red;
  }
}

@primary-color: #1DA57A;