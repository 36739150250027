.nav-bar {
  background-color: #141414;
  border-bottom: 1px solid #303030;
  padding: 10px 30px;
  height: 58px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .links {
    flex: 1;
    padding: 0 0 0 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .trade-link {
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
  .wallet-link {
    font-size: 16px;
    color: #fff;
    padding: 4px 6px;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    .hide-mobile {
      display: none;
    }
    .wallet-link {
      border: 1px solid #e3e3e3;
    }
  }
}

@primary-color: #1DA57A;