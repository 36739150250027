@import url('../../variables.less');

.order-book {
  max-height: calc(55% + 41px);
  height: 100%;
  border-bottom: 1px solid rgb(38, 38, 38);
  padding-bottom: 20px;
  .container {
    height: calc(100% - 55px);
    overflow: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .current-price {
    font-size: 16px;
    color: #42b47f;
    padding: 15px 0px;
    font-weight: bold;
    .ant-col {
      color: rgba(255, 255, 255, 0.5);
      &:nth-child(1) {
        justify-content: start;
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    padding: 16px;
  }
  .filter {
    padding: 0 10px;
    padding-bottom: 10px;
    img {
      margin: 0 6px;
      cursor: pointer;
      &.selected {
        border: 1px solid #42b47f;
      }
    }
  }
  .ant-row {
    padding: 0px 6px;
    font-size: 12px;
    position: relative;
    height: 28px;
  }
  .ant-col {
    padding: 0px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div {
      text-align: right;
      flex: 1;
    }
    &:nth-child(2) {
      justify-content: start;
    }
  }
  .title {
    .ant-col {
      font-size: 10px;
      // font-weight: 300;
      letter-spacing: -0.5px;
      color: rgba(255, 255, 255, 0.5);
      &:nth-child(1) {
        justify-content: start;
      }
      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
  }
}

.green {
  .ant-col:last-child {
    color: rgba(255, 255, 255, 0.5);
  }
  .percentage {
    position: absolute;
    height: 100%;
    right: 0%;
    top: 0;
    background-color: #42b47f;
    opacity: 0.3;
  }

  .ant-col:nth-child(2) {
    color: #42b47f;
  }
  .ant-col div:last-child {
    position: relative;
  }
}

.red {
  .ant-col:nth-child(2) {
    color: #db4e4e;
  }
  .ant-col:last-child {
    color: rgba(255, 255, 255, 0.5);
  }
  .percentage {
    position: absolute;
    height: 100%;
    right: 0%;
    top: 0;
    background-color: #db4e4e;
    opacity: 0.3;
  }
  .ant-col div:last-child {
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .ant-tabs-tabpane .order-book {
    max-height: 100%;
  }
}

@primary-color: #1DA57A;