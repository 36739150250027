@import url('../../variables.less');

.order-entry {
  .ant-switch-checked {
    background-color: #5cd1f5;
  }
  background-color: #000;
  .ant-slider-with-marks {
    margin-bottom: 18px;
    margin-top: 4px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &.buy .order-type > span:first-child,
  &.sell .order-type > span:last-child {
    font-weight: bold;
  }
  width: 100%;
  .order-details {
    margin-bottom: 40px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #22262e;
    }
  }
  .order-details,
  .order-summary,
  .oder-action {
    padding: 0px 10px;
  }
  .summary-title {
    margin-bottom: 10px;
    span {
      font-weight: 600;
    }
  }
  .oder-action {
    margin-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    .ant-btn-primary {
      width: 100%;
      padding: 10px 20px;
      height: 48px;
      font-size: 12px;
    }
  }
  .order-summary {
    margin-top: 30px;
    .wallet-balance {
      margin-bottom: 10px;
      padding-left: 6px;
      padding-right: 6px;
    }
    .unsettled-balance {
      padding-left: 6px;
      padding-right: 6px;
      margin-top: 20px;
      padding-top: 20px;
    }
    .summary-item {
      display: flex;
      font-size: 12px;
      margin-bottom: 10px;
      justify-content: space-between;
      span:first-child {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .order-type {
    display: flex;
    border-bottom: 2px solid #222;
    padding: 0 16px;
    .ant-radio-group {
      width: 100%;
      display: flex;
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
      .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: transparent;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        font-weight: bold;
      }
      label {
        width: 50%;
        text-align: center;
        padding: 15px 20px;
        box-sizing: content-box;
        border: none;
        & > span {
          text-align: center;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .order-price {
    .ant-radio-button-wrapper-checked {
      font-weight: bold;
      span {
        color: #fff;
      }
    }
    .ant-radio-button-wrapper {
      border: none;
      display: inline-block;
      font-size: 12px;
      width: 90px;
      box-sizing: content-box;
      text-align: left;
      padding: 10px 6px;
      color: rgba(255, 255, 255, 0.5);
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: transparent;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: transparent;
    }
    .ant-input-group {
      font-size: 12px;
      padding: 8px 0;
      background-color: #22262e;
      border-radius: 6px;
      height: 40px;
      margin-bottom: 16px;
      text-align: right;
    }
    .ant-input,
    .ant-input:focus,
    .ant-input-group-addon {
      outline: none;
      box-shadow: none;
      border: none;
      border-color: transparent;
      background-color: #22262e;
    }
    .ant-input-group-addon:first-child {
      color: rgba(255, 255, 255, 0.5);
      width: 64px;
    }
    .ant-input-group-addon {
      padding: 0 10px;
      font-size: 12px;
      text-align: left;
    }
    .ant-input-group-wrapper {
      padding: 0 6px;
    }
    .quick-select {
      margin-bottom: 10px;
      .ant-radio-group {
        font-size: 12px;
        display: flex;
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          font-weight: normal;
        }
        & > label {
          border-radius: 6px;
          text-align: center;
          flex: 1;
          background-color: #22262e;
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .slider {
      padding: 0 6px;
      .ant-slider-track {
        background-color: #325f89;
        height: 8px;
      }
      .ant-slider-step,
      .ant-slider-rail {
        height: 8px;
      }
      .ant-slider-dot {
        height: 10px;
        width: 10px;
        border-color: transparent;
        background-color: #53555a;
        &.ant-slider-dot-active {
          background-color: darken(#5cd1f5, 15%);
        }
      }
      .ant-slider-handle {
        height: 14px;
        width: 14px;
        background-color: #5cd1f5;
      }
    }
    .fee {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-bottom: 0px;
      padding: 0 6px;
      span:first-child {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .order-advanced {
    margin-bottom: 10px;
    padding: 0 6px;
    .ant-collapse {
      font-size: 12px;
      background-color: transparent;
      border: none;
      .ant-collapse-header {
        padding-left: 0;
        color: rgba(255, 255, 255, 0.5);
      }
      .ant-collapse-item {
        border: none;
      }
    }
    .ant-collapse-content {
      background-color: transparent;
      .ant-collapse-content-box {
        padding: 0;
        font-size: 12px;
      }
    }
    .option-container {
      flex: 1;
      display: flex;
      padding: 10px 0;
      div:first-child {
        color: rgba(255, 255, 255, 0.5);
      }
      .toggle-container {
        margin-right: 20px;
        display: flex;
        .title {
          margin-right: 10px;
        }
      }
    }
  }
}
.sell {
  .order-type {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: @red;
    }
  }

  .ant-btn-primary {
    border-color: @red;
    background: @red;
  }
}
.buy {
  .order-type {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: @green;
    }
  }
  .ant-btn-primary {
    border-color: @green;
    background: @green;
  }
}

.sell .order-type {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-weight: bold;
    border-bottom: 2px solid @red;
  }
}
.buy .order-type {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    font-weight: bold;
    border-bottom: 2px solid @green;
  }
  .ant-radio-button-wrapper:last-child:hover {
    span {
      color: @red;
    }
  }
}

@primary-color: #1DA57A;