@import url('../../variables.less');
.mobile-trade {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  justify-content: space-between;
  padding: 20px 16px;
  border-top: 1px solid #303030;
  background-color: #000;
  .balances {
    display: flex;
    .title {
      color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
    }
    div {
      width: 66px;
      text-align: left;
    }
    .base, .quote, .title {
      text-align: right;
      padding: 0 10px 0 0;
    }
  }
  .action-buttons {
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: space-around;
    .buy span {
      background-color: @green;
    }
    .sell span {
      background-color: @red;
    }
    .settle span {
      background: #666;
    }
    span {
      display: inline-block;
      text-align: center;
      font-size: 16px;
      padding: 6px 0;
      width: 64px;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .mobile-trade {
    display: flex;
  }
}

@primary-color: #1DA57A;