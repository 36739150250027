@import url('../../variables.less');
.trades {
  height: 40%;
  position: relative;
  overflow: hidden;
  .title {
    font-size: 14px;
    font-weight: bold;
    padding: 16px;
    background-color: #000;
  }
  .ant-table,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background-color: transparent;
    border-bottom: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px 16px;
    text-align: right;
    &:first-child {
      text-align: left;
    }
  }
  td > span.buy {
    color: @green;
  }
  td > span.sell {
    color: #db4e4e;
  }
  .ant-table-cell:last-child {
    color: rgba(255, 255, 255, 0.5);
  }

  .ant-table-thead > tr > th {
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
      // font-weight: 300;
      letter-spacing: -0.5px;
  }
  .table {
    padding: 0px 15px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }
  .overflow {
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media screen and (max-width: 768px) {
  .trades {
    height: 100%;
  }
}

@primary-color: #1DA57A;