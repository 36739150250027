.market {
  border-right: 1px solid #303030;
  position: relative;
  z-index: 2;
  width: 540px;
  padding: 10px 20px 20px;
  .ant-btn {
    padding: 0 8px;
    display: inline-block;
    height: 24px;
    width: 42px;
  }
  .ant-btn-primary {
    border-color: #5cd1f5;
    background: #5cd1f5;
  }
  h1 {
    &:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .anticon {
    font-size: 16px;
  }
  .tabs {
    margin: 10px auto;
    .tab {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .ant-table {
    font-size: 13px;
    font-family: atlas-typewriter, monospace, btcglyph;
  }
  .percentage {
    font-size: 11px;
  }
  .percentange-up {
    color: #0a9660;
  }
  .percentange-down {
    color: #b12f52;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 8px;
    text-align: right;
  }
  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child,
  .ant-table tfoot > tr > th:first-child,
  .ant-table tfoot > tr > td:first-child {
    text-align: left;
  }
  .ant-table-tbody > tr > td:first-child {
    font-weight: bold;
    letter-spacing: 1px;
  }
  .small {
    font-size: 11px;
    color: #5c6067;
    text-align: right;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-table-tbody {
    max-height: calc(100vh - 162px);
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .market {
    width: 100%;
    padding: 10px;
    // position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
  }
}

@primary-color: #1DA57A;