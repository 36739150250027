.orders {
  .ant-tabs-nav-wrap {
    padding: 6px 15px;
  }
  .ant-tabs-nav {
    background-color: rgba(39, 46, 56, 0.3);
    border-bottom: 1px solid #272e38;
    border-top: 1px solid #272e38;
  }
  &.ant-tabs-top > .ant-tabs-nav::before,
  &.ant-tabs-bottom > .ant-tabs-nav::before,
  &.ant-tabs-top > div > .ant-tabs-nav::before,
  &.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom-color: transparent !important;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    color: #fff;
  }
  .ant-tabs-ink-bar {
    background-color: transparent;
  }
  .ant-table,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background-color: transparent;
    border-bottom: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 12px 20px;
    text-align: right;
    &:first-child {
      text-align: left;
    }
  }
  .ant-table-thead > tr > th {
    color: rgba(255, 255, 255, 0.5);
  }
  .table {
    padding: 0px 15px;
  }
}

.Sell {
  color: #ec4a4a;
  font-size: 12px;
}
.Buy {
  color: #42b47f;
  font-size: 12px;
}
.no-wallet-message {
  height: calc(45% - 82px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .no-wallet-message {
    min-height: 25vh;
  }
}

@primary-color: #1DA57A;