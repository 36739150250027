.TVChartContainer {
  height: 55%;
}

@media screen and (max-width: 768px) {
  .TVChartContainer {
    height: 100%;
  }
}

@primary-color: #1DA57A;