.page-contaienr {
  position: relative;
  .market-list {
    background-color: #000;
    position: absolute;
    top: 0;
    left: -540px;
    height: calc(100vh - 54px);
    transition: left 0.5s ease-in;
  }
  .market-list.display {
    left: 0;
    .overlay {
      width: 100vw;
      z-index: 1;
      position: absolute;
      height: 100vh;
      background: rgba(0, 0, 0, 0.75);
      left: 0;
      top: 0;
    }
  }
}
.mobile {
  display: none;
}
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100vh;
//   z-index: 999;
//   background-color: rgba(0, 0, 0, 0.5);
// }

// @media screen and (max-width: 768px) {
//   .page-contaienr {
//     display: none;
//   }
// }

.height-col {
  border-right: 1px solid #262626;
  height: calc(100vh - 55px);
}
.mobile-view {
  display: none;
}
@media screen and (max-width: 768px) {
  .height-col {
    border-right: none;
    height: auto;
  }
  .market-list {
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh);
    display: none;
    width: 100%;
  }
  .market-list.display {
    display: block;
  }
  .mobile-view {
    display: block;
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: bold;
    }
  }
  .desktop-view {
    display: none;
  }
  .mobile-order-entry.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    .back {
      text-align: right;
      padding: 20px 16px 0;
      font-size: 14px;
    }
    .order-type {
      display: none;
    }
  }
}
.mobile-order-entry {
  display: none;
}

[data-reach-dialog-overlay] {
  z-index: 999999;
  .goki-walletkit-modal-wrapper {
    background: black;
    border: 2px solid #303030;
    span,
    p,
    h2,
    div {
      color: white;
    }
    h2 ~ div svg {
      fill: white;
    }
    h2 ~ div div,
    div:nth-of-type(2) div div,
    svg + div {
      background: black;
    }
    button {
      border: 1px solid #303030;
    }
    a {
      color: #303030;
    }
  }
}

@primary-color: #1DA57A;