/*
  @import '~antd/dist/antd.dark.less'; // 引入官方提供的暗色 less 样式入口文件
  @import '~antd/dist/antd.compact.less'; // 引入官方提供的紧凑 less 样式入口文件
*/
@import '~antd/dist/antd.dark.less';
@import '~antd/dist/antd.compact.less';
@import './variables.less';

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
iframe html.theme-dark .chart-controls-bar {
  background-color: #fff;
}

@primary-color: #1DA57A;